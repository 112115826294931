import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import AvatarContent from "./Avatar";
import { BellOutlined } from "@ant-design/icons";

import LoadingOverlay from "components/Indicator/LoadingOverlay";
import { setUserProfile } from "reducers/profile";

import WithForgotPassword from "./actions";

class Userbox extends Component {
	render = () => {
		return (
			<div className="header-btn-lg pr-0 ml-0" style={{ paddingLeft: "1rem" }}>
				<div className="widget-content p-0">
					<div className="widget-content-wrapper">
						<div className="widget-content-left d-flex align-items-center">
							{/* <NotificationContent
								history={this.props.history}
								notification={[]}
								setNotificationRead={() => {}}
								onToggleDetail={() => {}}
								onChangeSelected={() => {}}
							/> */}
							{
								this.props.showNotification && (
									<BellOutlined
										style={{ fontSize: "18px", cursor: "pointer" }}
										className="mr-2"
										onClick={() => this.props.onShowNotification()}
									/>
								)
							}
							<AvatarContent {...this.props} />
						</div>
					</div>
				</div>
				{this.props.onLoadForgotPassword && <LoadingOverlay />}
			</div>
		);
	};
}

export default compose(
	connect(null, { setUserProfile }),
	WithForgotPassword
)(Userbox);
