import { Col, Row } from "antd";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { Button, Label } from "reactstrap";

import LogoImg from "assets/Images/logo.png";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import CustomInput from "components/Input/formInput";
import Tab from "components/Tab";
import TemplateContainerMain from "components/Template";

import { getItem, clearItem } from "utils/tokenStore";
import WithLogin from "./action";
import "./index.scss";

const Login = (props) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [currentTab, setCurrentTab] = useState("team");

	const { onChangeHOC, showPassword } = props;

	useEffect(() => {
		if (props.location.search) {
			clearItem("MAZAK_ACCESS_TOKEN");
			props.setPath([]);
		} else {
			if (getItem("MAZAK_ACCESS_TOKEN")) {
				props.history.push("/dashboard");
			} else {
				clearItem("HAS_SHOWN_ATTENDANCE")
				props.setPath([]);
			}
		}
	}, []);

	useEffect (() => {
		setPassword( '' )
		setUsername( '' )
	}, [currentTab])

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			if (username.length < 1 || password.length < 1) {
				return false;
			}

			props.onClickLogin({
				email: username,
				password: password,
			}, currentTab);
		}
	};

	return (
		<>
			<TemplateContainerMain>
				<div className="h-100">
					<Row className="h-100">
						<Col
							span={24}
							className="h-100 d-flex justify-content-center align-items-center"
							style={{ background: "#1c212d" }}>
							<Col
								xl={8}
								lg={12}
								md={18}
								sm={24}
								xs={24}
								className="mx-auto app-login-box">
								<img
									src={LogoImg}
									alt="mazak-logo"
									style={{ width: 200 }}
									className="d-block mx-auto mb-5"
								/>
								<AvForm
									style={{
										background: "#f9fafb",
										padding: "25px",
										borderRadius: "10px",
									}}>
									<h5
										style={{
											fontWeight: "600",
										}}
										className="mb-1 text-primary">
										Welcome Back!
									</h5>
									<div className="mb-4">Fill out your account information.</div>
									<Tab
										selectedSection={currentTab}
										onChangeSection={(val) => setCurrentTab(val)}
										style={{ marginBottom: 24 }}
										tabSections={[
											{ label: "Mazak Team", value: "team" },
											{ label: "Dealer", value: "dealer" },
										]}
									/>
									<Row gutter={16}>
										<Col md={12} sm={24} xs={24}>
											<AvGroup>
												<Label for="exampleUsername">User ID/ Email</Label>
												<CustomInput
													value={username}
													onChangeData={(val) => setUsername(val)}
													placeholder={"User ID/ Email"}
													onKeyPress={handleKeyPress}
												/>
											</AvGroup>
										</Col>
										<Col md={12} sm={24} xs={24}>
											<AvGroup>
												<div className={"d-flex"}>
													<Label for="examplePassword">Password</Label>
													<div
														style={{
															width: 14,
															height: 14,
															marginLeft: 10,
															cursor: "pointer",
														}}
														onClick={() =>
															onChangeHOC(!showPassword, "showPassword")
														}></div>
												</div>
												<CustomInput
													type={"password"}
													value={password}
													onKeyPress={handleKeyPress}
													onChangeData={(val) => setPassword(val)}
													placeholder={"Password"}
												/>
											</AvGroup>
										</Col>
									</Row>
									{props.errorMessage && (
										<p style={{ color: "#ff0000" }}>{props.errorMessage}</p>
									)}
									<Row className="divider mx-1" />
									<Button
										color="primary btn-block my-4"
										size="lg"
										onKeyDown={handleKeyPress}
										disabled={!username || !password}
										onClick={() => {
											props.onClickLogin({
												email: username,
												password: password,
											}, currentTab);
										}}>
										Login
									</Button>
									<a
										className="d-flex justify-content-center align-items-center forget-pw-link"
										href="#"
										onClick={(e) => {
											e.preventDefault();
											props.history.push("/reset-password-form");
										}}>
										Forget password? Reset here.
									</a>
								</AvForm>
							</Col>
						</Col>
					</Row>
				</div>
				{props.onLoadLogin && <LoadingOverlay />}
			</TemplateContainerMain>
		</>
	);
};

export default WithLogin(Login);
