import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import Breadcrumb from "components/Breadcrumb";
import HeaderLogo from "./Logo";
import Userbox from "./Userbox";

import BreadcrumbComponent from "components/Breadcrumb";
import { moduleNameFinder } from "utils/moduleNamefinder";

import "./index.scss";

const Header = ({
	headerBackgroundColor,
	enableMobileMenuSmall,
	enableHeaderShadow,
	history,
	showNotification,
	onClickToggleProfileModal,
	onShowNotification,
	user,
	location,
}) => {
	return (
		<>
			<ReactCSSTransitionGroup
				component="div"
				className={cx("app-header", headerBackgroundColor)}
				transitionName="HeaderAnimation"
				transitionAppear={true}
				transitionAppearTimeout={1500}
				transitionEnter={false}
				transitionLeave={false}>
				<HeaderLogo location={location} />
				<div
					className={cx("app-header__content", {
						"header-mobile-open": enableMobileMenuSmall,
					})}>
					<div className="app-header-left">
						<BreadcrumbComponent />
					</div>
					<div className="app-header-right">
						<Userbox
							showNotification={showNotification}
							onShowNotification={onShowNotification}
							onClickToggleProfileModal={onClickToggleProfileModal}
							history={history}
							user={user}
						/>
					</div>
				</div>
			</ReactCSSTransitionGroup>
		</>
	);
};

const mapStateToProps = (state) => ({
	enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
	closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
	headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(Header);
