import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import Moment from "moment";

function NotificationCard({ content, history }) {
	const onClick = () => {
		history.push(`/dashboard/service_request?service_id=${content.service_request.id}`);
		window.location.reload();
	}

	return (
		<div className="custom-notification-card">
			<div className="custom-notification-card-icon-cont">
				<AiOutlineFileText />
			</div>
			<div className="w-100">
				<p>
					You’ve received a new{" "}
					<a className="text-primary" style={{cursor: "pointer"}} onClick={onClick}>
						service <br /> request !
					</a>
				</p>
				<div className="custom-notification-card-date-cont">
					<span>{Moment(content.created_at).format('LT')}</span>
					<span>{Moment(content.created_at).format("DD/MM/YYYY")}</span>
				</div>
				<div className="custom-notification-card-serivce-cont">
					<strong>SERVICE NO</strong>
					<a style={{color: "#2970ff"}} onClick={onClick}>{content.service_request.service_no}</a>
				</div>
			</div>
		</div>
	);
}

export default NotificationCard;
