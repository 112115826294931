import React from "react";
import "./index.scss";
import Moment from "moment";
import { useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import NotificationCard from "./NotifcationCard";
import Loader from "react-loaders";
import { Empty } from 'antd'

const onHandleDatelabel = (val) => {
	if (Moment().format("DD/MM/YYYY") === val) {
		return "TODAY";
	}
	if (Moment().subtract(1, "days").format("DD/MM/YYYY") === val) {
		return "YESTERDAY";
	}

	return val;
};

function NotificationBar(props) {
	useEffect(() => {
		if (props.showNotification) {
			props.getNotifications(props.data.ProfileReducer.profile.id);
		}
		else {
			props.onChangeNotificationHOC("notifications", []);
			props.onChangeNotificationHOC("allNotifications", []);
			props.onChangeNotificationHOC("notificationsMeta", {});
			props.onChangeNotificationHOC("pageIndex", 1);
		}
	}, [props.showNotification]);

	const contentClass = props.showNotification ? "content-visible" : "content-hidden";

	return (
		<div
			style={{ position: "fixed", zIndex: 9999 }}
			className={`${
				props.showNotification ? "custom-notification-bar-parent-cont" : ""
			}`}>
			<div
				className={`custom-notification-bar sliding-content ${contentClass}`}>
				<div className="custom-notification-bar-header">
					<div className="custom-notification-bar-header-title">
						Notification
					</div>
					<IoIosClose onClick={() => props.onClose()}/>
				</div>
				<div className="custom-notification-bar-body" onScroll={e => props.onScroll(e)}>
					{
						props.notifications.length < 1 && (
							<div className="h-100" style={{ display: 'grid', placeContent: 'center' }}>
								<Empty
									description={<span>No notifications found</span>}
									image={Empty.PRESENTED_IMAGE_SIMPLE}
								/>
							</div>
						)
					}
					{ props.notifications.length > 0 &&
						props.notifications.map((section) => (
								<div key={`notification_${section.date}`}>
									<div className="custom-notification-bar-date-header">
										{onHandleDatelabel(section.date)}
									</div>
									{
										section.notifications.map((notificationChild) => (
											<NotificationCard key={notificationChild.id} content={notificationChild} history={props.history}/>
										))
									}
								</div>
							)
						)
					}
					{
						props.onLoadNotification && 
						<div className="text-center"  style={{background: "white"}}>
							<Loader type="ball-beat" />
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default NotificationBar;