import React, { Component } from "react";
import { connect } from "react-redux";

import Axios from "axios";
import { storeItem } from "utils/tokenStore";
import getDomainURL from "utils/api";
import { setUserProfile } from "reducers/profile";
import { setPath } from "actions/path";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			showPassword: false,
			errorMessage: "",
		};

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		onClickLogin = (dataToSubmit, currentTab) => {
			this.setState({ loading: true }, () => {
			  Axios.post(`${getDomainURL()}/login/${ currentTab === 'team' ? 'mazak' : 'dealer' }`, dataToSubmit)
			    .then((response) => {
			      this.setState({ loading: false });
			      this.props.setUserProfile( response.data.data )
			      storeItem("MAZAK_ACCESS_TOKEN", response.data.token);
			      this.props.history.push("/dashboard/home");
			    })
			    .catch((err) => {
			      this.setState({
			        errorMessage:
			          err.response?.data?.message ??
			          "This combination of username and password is incorrect.",
			        loading: false,
			      });
			    });
			});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					showPassword={this.state.showPassword}
					errorMessage={this.state.errorMessage}
					onLoadLogin={this.state.loading}
					onChangeHOC={this.onChangeHOC}
					onClickLogin={this.onClickLogin}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, { setUserProfile, setPath })(WithHOC);
};

export default HOC;
