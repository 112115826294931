export const defaultForm = {
  customer_name: '',
  requester_name: '',
  requester_contact: '',
  machine_id: '',
  controller_id: '',
  service_no: null,
  engineer_id: null,
  service_type_id: null,
  created_date: new Date(),
  visit_date: null,
  issue_details: '',
  action: 'pending',
  status: 'pending',
  is_chargeable: true,
  is_quoted: false,
  is_under_warranty: false,
  is_invoiced: false,
  has_valid_location: true,
  has_valid_customer_name: true,
  is_commercial_purpose_usage: true,
  service_details: null,
  remedy_details: null,
  parts_changed: null,
  remarks: null,
  serial_number: '',
  machine_model: '',
  service_request_file: {},
  working_details: []
}