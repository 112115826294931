import Axios from "axios";
import getDomainURL from "utils/api";
import Cookies from "js-cookie";
import { clearItem } from "utils/tokenStore";
import FileSaver from "file-saver";

const defaultMessage =
	"You are disconnected from the internet, please reconnect to use ERP. If problem persists, please contact the system admin.";

export const Get = (url, response, error, load) => {
	load(true);
	let token = Cookies.get("MAZAK_ACCESS_TOKEN");
	Axios.defaults.headers = {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${token}`,
	};
	Axios.defaults.responseType = "json";
	return Axios.get(`${getDomainURL()}${url}`)
		.then((res) => {
			response(res.data);
			load(false);
		})
		.catch((err) => {
			if (err && err.response) {
				if (err.response.status === 401) {
					clearItem("MAZAK_ACCESS_TOKEN");
					window.location.reload();
				} else if (err.response.status === 500) {
					error(
						"Server encountered issues. Please contact your system admin for assistance."
					);
				} else {
					error(err.response.data.message);
				}
			} else if (err.response) {
				error(err.response.data.message);
			} else {
				error(defaultMessage);
			}
			load(false);
		});
};

export const Post = (url, data, response, error, load) => {
	load(true);
	let token = Cookies.get("MAZAK_ACCESS_TOKEN");
	Axios.defaults.headers = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${token}`,
	};
	Axios.defaults.responseType = "json";
	return Axios.post(`${getDomainURL()}${url}`, data)
		.then((res) => {
			response(res.data);
			load(false);
		})
		.catch((err) => {
			if (err && err.response) {
				error(err.response?.data ?? defaultMessage);
			} else {
				error(defaultMessage);
			}
			load(false);
		});
};

export const Put = (url, data, response, error, load) => {
	load(true);
	let token = Cookies.get("MAZAK_ACCESS_TOKEN");
	Axios.defaults.headers = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${token}`,
	};
	Axios.defaults.responseType = "json";
	return Axios.put(`${getDomainURL()}${url}`, data)
		.then((res) => {
			response(res.data);
			load(false);
		})
		.catch((err) => {
			console.dir(err, "err");
			if (err && err.response && err.response.status) {
				if (err.response.status === 500) {
					error(
						"Server encountered issues. Please contact your system admin for assistance."
					);
				} else if (err.response.status === 422) {
					error(err.response?.data ?? defaultMessage);
				}
			} else if (err) {
				error(err.response.data[0]);
			} else {
				error(defaultMessage);
			}
			load(false);
		});
};

export const Delete = (url, response, error, load) => {
	load(true);
	let token = Cookies.get("MAZAK_ACCESS_TOKEN");
	Axios.defaults.headers = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${token}`,
	};
	Axios.defaults.responseType = "json";
	return Axios.delete(`${getDomainURL()}${url}`)
		.then((res) => {
			response(res.data);
			load(false);
		})
		.catch((err) => {
			if (err && err.response && err.response.status) {
				if (err.response.status === 500) {
					error(
						"Server encountered issues. Please contact your system admin for assistance."
					);
				} else {
					error(err.response?.data?.message ?? defaultMessage);
				}
			} else if (err) {
				error(err.response?.data?.message ?? defaultMessage);
			} else {
				error(defaultMessage);
			}
			load(false);
		});
};

export const GetFile = async (url, savedFilename, response, error, load) => {
	load(true);
	let token = Cookies.get("MAZAK_ACCESS_TOKEN");
	Axios.defaults.headers = {
		"Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${token}`,
	};
	Axios.defaults.responseType = "blob";
	return Axios.get(`${getDomainURL()}${url}`)
		.then(async (res) => {
			load(false);
			if (res.status === 200) {
				const resultBlob = new Blob([res.data]);
				FileSaver.saveAs(resultBlob, savedFilename);
				response(resultBlob);
			}
		})
		.catch((err) => {
			if (err && err.response) {
				error(err.response?.data ?? defaultMessage);
			} else {
				error(defaultMessage);
			}
			load(false);
		});
};