import React from "react"
import { Input, Space } from "antd"

const PasswordInput = ({
  inputStyle,
  onChange,
  onKeyPress,
  placeholder,
  status,
  value,
}) => {
  return (
    <Input.Password
      style={inputStyle}
      placeholder={placeholder || "Password"}
      status={status}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  )
}
export default PasswordInput