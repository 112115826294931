import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Button,
	DropdownMenu,
	DropdownToggle,
	UncontrolledButtonDropdown,
} from "reactstrap";
import { clearItem } from "utils/tokenStore";

import avatar1 from "assets/Images/logo.png";

function Avatar({ user, history }) {
	return (
		<UncontrolledButtonDropdown>
			<DropdownToggle color="link" className="p-0">
				<img
					style={{ width: 24, height: 24, objectFit: "cover" }}
					className="rounded-circle"
					src={avatar1}
					alt=""
				/>
			</DropdownToggle>
			<DropdownMenu right className="rm-pointers dropdown-menu-lg p-0">
				<div className="dropdown-menu-header" style={{ marginBottom: 0 }}>
					<div className="dropdown-menu-header-inner bg-white">
						<div className="menu-header-image opacity-2" />
						<div className="menu-header-content text-left">
							<div className="widget-content p-0">
								<div className="widget-content-wrapper">
									<div className="widget-content-left mr-3">
										<img
											style={{ width: 24, height: 24, objectFit: "cover" }}
											className="rounded-circle"
											src={avatar1}
											alt=""
										/>
									</div>
									<div className="widget-content-left">
										<div className="widget-heading text-dark">{`${
											user.name || "Username"
										}`}</div>
										<div className="widget-subheading text-dark opacity-8">
											{user.email || "Email"}
										</div>
									</div>
									<div className="widget-content-right mr-2">
										<Button
											className="btn-pill bg-primary text-light"
											color="primary"
											onClick={() => {
												clearItem("MAZAK_ACCESS_TOKEN");
												history.push("/login");
											}}>
											Logout
										</Button>
									</div>
								</div>
							</div>
							{/* <div className="mt-4">
								<Button
									className="btn-pill bg-dark text-light btn-shadow btn-shine"
									color="focus"
									onClick={() => {
										getSelectedUser(data.ProfileReducer.profile.user.id);
									}}>
									Change Password
								</Button>
							</div> */}
						</div>
					</div>
				</div>
			</DropdownMenu>
		</UncontrolledButtonDropdown>
	);
}

export default Avatar;
