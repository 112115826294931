import _ from 'lodash'
import { useEffect } from 'react'
import { Col, Form, Row } from 'reactstrap'
import { toast } from "react-toastify";

import CustomCard from 'components/Card'
import CustomButton from 'components/Button'
import FormSelect from "components/Input/select";
import FormInput from 'components/Input/formInput'
import CustomUpload from 'components/Input/fileUpload'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import Logo from 'assets/Images/logo.png'
import HOC from './action'
import './index.scss'

function PublicServiceRequest({
  formData,
  machines,
  controllers,
  onLoadRequest,
  selectedClient,
  preSelectedMachine,
  groupClients,

  getMachines,
  getControllers,
  createRequest,
  onChangeRequestHOC,
  getSelectedClient,
  getGroupClient
}) {

  useEffect(() => {
    getControllers()

    const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const clientId = urlParams.get('client_id');
    const serialNum = urlParams.get('serial_no');
    const groupID = urlParams.get('group_id');

    if (clientId) {
      getSelectedClient(clientId)
      getMachines(clientId, 'client')
    }

    else if (groupID) {
      getGroupClient(groupID)
    }

    else if (serialNum) {
      onChangeRequestHOC('preSelectedMachine', serialNum)
      onChangeField('machine_id', +serialNum)
    } else {
      getMachines()
    }
  }, [])

  useEffect(() => {
    if (groupClients?.[0]) {
      getMachines(groupClients, 'group')
    }
  }, [groupClients])

  useEffect(() => {
    if (selectedClient) {
      onChangeRequestHOC('formData', {
        ...formData,
        customer_name: selectedClient.name,
        requester_name: selectedClient.name,
        requester_contact: selectedClient.phone
      })
    }
  }, [selectedClient])

  useEffect(() => {
    let temp = _.find(machines, { id: formData.machine_id })
    if (temp) {
      onChangeRequestHOC('formData', {
        ...formData,
        controller_id: temp.controller_id,
        is_under_warranty: temp.is_under_warranty,
        ...temp.customer && {
          customer_name: temp.customer.name,
          requester_name: temp.customer.name,
          requester_contact: temp.customer.phone
        }
      })
    }
  }, [machines, formData.machine_id])

  const onChangeField = (key, val) => {
    onChangeRequestHOC('formData', {
      ...formData,
      [key]: val
    })
  }

  const onChangeFiles = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()
      try {
        reader.onload = e => {
          let tmp = {
            file_name: file.name,
            file_size: file.size,
            file_type: file.type,
            type: 'service request',
            file_content: e.target.result.replace(`data:${file.type};base64,`, "")
          }
          onChangeField('service_request_file', tmp)
        };
        reader.readAsDataURL(file)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <div className='w-100 h-100' style={{ display: 'grid', placeItems: 'center', padding: '20px 15px' }}>
      <div className="d-flex" style={{ marginBottom: 40 }}>
        <img className='m-auto' src={Logo} alt="mazak-logo" style={{ height: 52, width: 187 }} />
      </div>
      <CustomCard type={'borderless'} className={'custom-service-request-cont'}>
        <h5 className="text-primary mb-3" style={{ fontWeight: 600 }}>
          New Request
        </h5>
        <Form>
          <Row>
            <Col xl={12} lg={12}>
              <FormInput
                value={formData.customer_name}
                onChangeData={val => onChangeField('customer_name', val)}
                label={'Customer Name'}
                disabled={selectedClient || groupClients?.[0]}
                placeholder={'Customer Name'}
                required={true}
              />
            </Col>
            <Col xl={6} lg={6}>
              <FormInput
                value={formData.requester_name}
                onChangeData={val => onChangeField('requester_name', val)}
                label={'Requester Name'}
                placeholder={'Requester Name'}
              />
            </Col>
            <Col xl={6} lg={6}>
              <FormInput
                value={formData.requester_contact}
                onChangeData={val => onChangeField('requester_contact', val)}
                label={'Requester Contact'}
                placeholder={'Requester Contact'}
              />
            </Col>
            <Col xl={6} lg={6}>
              <FormSelect
                context={"machine_id"}
                value={formData.machine_id}
                required={true}
                label={'Serial Number'}
                disabled={preSelectedMachine}
                placeholder={'Serial Number'}
                emptyOption={true}
                options={machines?.map((machine) => ({
                  value: machine.id,
                  label: machine.serial_number,
                }))}
                onChangeData={val => {
                  onChangeField('machine_id', Number(val))
                }}
              />
            </Col>
            <Col xl={6} lg={6}>
              <FormInput
                value={formData.machine_id
                  ? _.find(machines, { id: formData.machine_id })?.machine_model?.name
                  : ''
                }
                disabled={true}
                required={true}
                label={'Machine Model'}
                placeholder={'Machine Model'}
              />
            </Col>
            <Col lg={12}>
              <FormSelect
                context={"controller_id"}
                value={formData.controller_id}
                required={true}
                label={'Controller'}
                disabled={true}
                placeholder={'Controller'}
                emptyOption={true}
                options={controllers?.map((controller) => ({
                  value: controller.id,
                  label: controller.name,
                }))}
                onChangeData={val => onChangeField('controller_id', Number(val))}
              />
            </Col>
            <Col lg={12}>
              <FormInput
                value={formData.issue_details}
                onChangeData={val => onChangeField('issue_details', val)}
                label={'Issue Details'}
                type={'textarea'}
                required={true}
                placeholder={'Describe your issues'}
              />
            </Col>
            <Col lg={12}>
              <CustomUpload
                value={formData.service_request_file}
                onClickRemove={() => onChangeField('service_request_file', {})}
                uploadButtonStyle={{ justifyContent: 'flex-start', width: '100%', textAlign: 'left', borderRadius: '8px', alignItems: 'center' }}
                labelStyle={{ marginBottom: 0 }}
                onChange={onChangeFiles}
                label={'Service Request Attachment'}
              />
            </Col>
          </Row>
        </Form>
        <hr className='w-100' />
        <CustomButton
          size={'xl'}
          color={'primary'}
          disabled={!formData.customer_name || !formData.machine_id
            || !formData.controller_id || !formData.issue_details}
          style={{ padding: 12 }}
          onClick={() => createRequest({
            ...formData,
            ... !_.isEmpty(formData.service_request_file) && {
              attachments: [formData.service_request_file]
            }
          })}>
          Submit
        </CustomButton>
      </CustomCard>
      {onLoadRequest && <LoadingOverlay />}
    </div>
  )
}

export default HOC(PublicServiceRequest)