import React, { Component } from "react"
import { connect } from "react-redux"

import Axios from "axios"
import getDomainURL from "utils/api"
import { requestSuccess } from "utils/requestHandler"

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showNewPassword: false,
      showConfirmPassword: false,
      errorMessage: "",
    }

    onChangeHOC = (val, context) => this.setState({ [context]: val })

    resetPassword = (dataToSubmit, token) => {
      this.setState({ loading: true }, () => {
        Axios.put(
          `${getDomainURL()}/api/profile/password`,
          dataToSubmit,
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
          .then((response) => {
            this.setState({ loading: false })
            this.props.history.push("/login")
            requestSuccess(
              "You have successfully reset the password. Please proceed to login.", "Success"
            )
          })
          .catch((err) => {
            this.setState({
              errorMessage:
                err.response?.data?.message ??
                "Oops, something happened. Please try again.",
              loading: false,
            })
          })
      })
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          showNewPassword={this.state.showNewPassword}
          showConfirmPassword={this.state.showConfirmPassword}
          errorMessage={this.state.errorMessage}
          onLoadLogin={this.state.loading}
          onChangeHOC={this.onChangeHOC}
          resetPassword={this.resetPassword}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state })
  return connect(mapStateToProps)(WithHOC)
}

export default HOC
