import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
    };

    load = (param) => this.setState({ loading: param });
    requestError = (error) => this.setState({ errorMessage: error });
    requestSuccess = (success) => toast.success(success);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
        />
      );
    };
  }
  return WithHOC;
};

export default HOC;
