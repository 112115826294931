import { setPath } from "actions/path";
import { getProfile } from "actions/profile";
import { Component } from "react";
import { connect } from "react-redux";
import { Get, Put } from "utils/axios";
import { convertObjectToBase64 } from "utils/objToBase64";
import { requestError, requestSuccess } from "utils/requestHandler";

import _ from 'lodash';
import Moment from 'moment';

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			accessNotificationUsers: [],
			loading: false,
			notifications: [],
			allNotifications: [],
			notificationsMeta: {},
			pageIndex: 1,
			user_id: 0,

			hasMarkedAttendance: false
		};

		load = (param) => this.setState({ loading: param });

		onChangeNotificationHOC = (key, val) => this.setState({ [key]: val });

		getNotificationUsers = () => Get(
			`/api/get_dashboard_notification_users`,
			this.getNotificationUsersSuccess,
			this.getNotificationUsersError,
			this.load
		);
		getNotificationUsersSuccess = (payload) =>
			this.setState({
				accessNotificationUsers: payload.data,
			});
		getNotificationUsersError = (error) => requestError(error.message, "Error");


		getNotifications = (user_id) => {
			this.setState({
				user_id: user_id
			});

			let query = {
				filter: { receiver_id: user_id },
				sorter: ["created_at", "DESC"],
				page: this.state.pageIndex,
				page_size: 10,
			};

			Get(
				`/api/notifications?query=${convertObjectToBase64(query)}`,
				this.getNotificationsSuccess,
				this.getNotificationsError,
				this.load
			);
		}
		getNotificationsSuccess = (payload) => {
			let groupByDate;
			let ordered;

			if (this.state.pageIndex > 1) {
				ordered = _.orderBy([...this.state.allNotifications, ...payload.data], [(element) => Moment(element.created_at)], ['desc'])
				ordered = _.uniqBy(ordered, 'id');

				this.setState({
					allNotifications: ordered
				});

				groupByDate = _.groupBy(ordered, element => Moment(element.created_at).format("DD/MM/YYYY"));
			}
			else {
				this.setState({
					allNotifications: payload.data,
				});

				groupByDate = _.groupBy(payload.data, element => Moment(element.created_at).format("DD/MM/YYYY"));
			}

			let notifications = _.map(groupByDate, (items, date) => ({
				date: date,
				notifications: items
			}));

			this.setState({
				notifications: notifications,
				notificationsMeta: payload.meta
			});
		}
		getNotificationsError = (error) => requestError(error.message, "Error");

		onScroll = (e) => {
			if (this.state.pageIndex <= this.state.notificationsMeta.totalPages) {
				let bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 3;
				if (bottom) {
					this.setState({
						pageIndex: this.state.pageIndex + 1
					}, () => this.getNotifications(this.state.user_id));
				}
			}
		}

		getAttendanceForToday = () => Get(
			`/api/get_user_attendance`,
			payload => this.setState({ hasMarkedAttendance: payload.data?.is_attended }),
			error => requestError(error),
			this.load
		)

		markAttendance = () => Put(
			`/api/mark_user_attendance`,
			{},
			this.markAttendanceSuccess,
			this.markAttendanceError,
			this.load
		)
		markAttendanceSuccess = payload => {
			requestSuccess('Attendance marked successfully.')
			this.setState({
				hasMarkedAttendance: true
			})
		}
		markAttendanceError = (error) => requestError(error.message, "Error");

		render = () => {
			return (
				<>
					<WrappedComponent
						{...this.props}
						{...this.state}
						onLoadNotification={this.state.loading}
						markAttendance={this.markAttendance}
						getAttendanceForToday={this.getAttendanceForToday}
						onChangeNotificationHOC={this.onChangeNotificationHOC}
						getNotificationUsers={this.getNotificationUsers}
						getNotifications={this.getNotifications}
						onScroll={this.onScroll}
					/>
				</>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps, { setPath, getProfile })(WithHOC);
};

export default HOC;
