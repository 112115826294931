import React, { useState } from "react";
import { Button } from "reactstrap";
import { Row, Col } from "antd";
import { AvForm } from "availity-reactstrap-validation";
import { useLocation } from 'react-router-dom';
import _ from "lodash";

import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import PasswordInput from "components/Input/password";
import WithForgotPw from "./actions";

const ForgotPW = (props) => {
  const useQuery = () => new URLSearchParams(useLocation().search)
  let query = useQuery()

  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (newPassword === null || newPassword === "" || confirmPassword === null || confirmPassword === "") {
        return false;
      }
    }
  };
  return (
    <TemplateContainerMain>
      <div className="h-100">
        <Row className="h-100">
          <Col span={24} className="login-landing-page">
            <Col xl={8} lg={12} md={18} sm={24} xs={24} className="mx-auto app-login-box">
              <AvForm
                style={{
                  padding: "25px",
                  borderRadius: "10px"
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <h4
                    className="mb-1 text-primary"
                  >
                    <b>Reset Password</b>
                  </h4>
                  <div className="mb-4 text-grey font-weight-heavy">Enter your new password below.</div>
                </div>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div>
                      <PasswordInput
                        containerClass={"password-input"}
                        inputStyle={{ borderRadius: "4px", width: '100%' }}
                        status={newPassword === "" ? "error" : null}
                        placeholder={"New Password"}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <PasswordInput
                        containerClass={"password-input"}
                        inputStyle={{ borderRadius: "4px", marginBottom: "8px" }}
                        status={confirmPassword === "" ? "error" : null}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="divider mx-1 mt-3 mb-4" />
                <Button
                  className="font-weight-heavy mb-3"
                  color="primary btn-block"
                  size="lg"
                  disabled={newPassword === null || newPassword === "" || confirmPassword === null || confirmPassword === ""}
                  onClick={() => {
                    if (newPassword !== "" && confirmPassword !== "" && (newPassword !== confirmPassword)) {
                      setShowErrorMessage(true)
                    } else {
                      props.resetPassword(
                        { password: newPassword, password_confirmation: confirmPassword }, 
                        query.get("token")
                      )
                    }
                  }}
                >
                  Reset Password
                </Button>
                <div className="d-flex flex-column">
                  {showErrorMessage && (
                    <span className="text-danger font-weight-heavy">
                      Passwords are not matched! Please enter again!
                    </span>
                  )}
                  {props.errorMessage && (
                    <span className="text-danger font-weight-heavy">
                      {props.errorMessage}
                    </span>
                  )}
                </div>
              </AvForm>
            </Col>
          </Col>
        </Row>
      </div>
      {props.onLoadLogin && <LoadingOverlay />}
    </TemplateContainerMain>
  );
};

export default WithForgotPw(ForgotPW);