import _ from 'lodash';
import { Component, Suspense } from "react";
import DocumentMeta from "react-document-meta";
import { Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import TemplateHeader from "components/Header";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import TemplateSidemenu from "components/Menu/Sidemenu";
import NotificationBar from "components/NotificationBar";
import TemplateContainerMain from "components/Template";
import { getItem, storeItem } from "utils/tokenStore";
import { isWeekend } from 'utils/isWeekend'

import { routes } from "./router";

import HOC from './action';

const PrefixURL = "/dashboard";

const meta = {
	meta: {
		name: {
			robots: "noindex",
		},
	},
};

const SuspenseWrapper = ({ children }) => (
	<Suspense fallback={<LoadingOverlay />}>{children}</Suspense>
);

class Dashboard extends Component {
	state = {
		showNotification: false,
	};
	componentDidMount = () => {
		if (!getItem("MAZAK_ACCESS_TOKEN")) {
			this.props.history.push("/login");
		} else {
			this.props.getNotificationUsers()

			let tempShown = getItem("HAS_SHOWN_ATTENDANCE")
			if(!tempShown) {
				this.props.getAttendanceForToday()
				storeItem("HAS_SHOWN_ATTENDANCE", true)
			} else {
				this.props.onChangeNotificationHOC('hasMarkedAttendance', true)
			}
		}
	};


	render = () => {
		return (
			<DocumentMeta {...meta}>
				<TemplateContainerMain>
					<TemplateHeader
						onClickToggleProfileModal={() => { }}
						onShowNotification={() => this.setState({ showNotification: true })}
						history={this.props.history}
						showNotification={_.findIndex(this.props.accessNotificationUsers, val => val === this.props.data.ProfileReducer.profile.id) > -1}
						user={{
							name: this.props.data.ProfileReducer.profile.name,
							email: this.props.data.ProfileReducer.profile.email,
						}}
					/>
					<TemplateSidemenu />
					<div className="app-main">
						<div className="app-main__outer">
							<div className="app-main__inner">
								{routes.map((route, index) => (
									<SuspenseWrapper key={index}>
										<Route
											exact={route.exact}
											path={`${PrefixURL}${route.path}`}
											render={(props) => route.render(props)}
										/>
									</SuspenseWrapper>
								))}
								{/* <PageFooter /> */}
							</div>
						</div>
					</div>
					{
						_.findIndex(this.props.accessNotificationUsers, val => val === this.props.data.ProfileReducer.profile.id) > -1 && (
							<NotificationBar
								onClose={() => this.setState({ showNotification: false })}
								showNotification={this.state.showNotification}
								history={this.props.history}
								{... this.props}
							/>
						)
					}
					<ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
					{
						!this.props.hasMarkedAttendance && 
						!isWeekend() &&
						['supervisor', 'support engineer'].indexOf(this.props.data.ProfileReducer.profile.role) > -1 && (
							<Modal
								isOpen={true}
								toggle={() => {
									this.props.onChangeNotificationHOC('hasMarkedAttendance', true)
								}}
							>
								<ModalHeader
									toggle={() => {
										this.props.onChangeNotificationHOC('hasMarkedAttendance', true)
									}}
								>
									Mark Attendance
								</ModalHeader>
								<ModalBody>
									Please click the button, to mark your attendance for today!
								</ModalBody>
								<ModalFooter>
									<Button
										color={'primary'}
										onClick={() => {
											this.props.markAttendance()
										}}
									>
										Check In
									</Button>
								</ModalFooter>
							</Modal>
						)
					}
				</TemplateContainerMain>
			</DocumentMeta>
		);
	};
}

export default HOC(Dashboard);
