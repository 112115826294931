import React, { Component } from "react";
import { connect } from "react-redux";

import { Put } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      errorMessage: "",
    };

    onChangeHOC = (val, context) => this.setState({ [context]: val });
    load = (param) => this.setState({ loading: param });

    requestForgetPasswordEmail = (dataToSubmit) => {
      Put(
        `/recover_account`,
        dataToSubmit,
        this.requestForgetPasswordEmailSuccess,
        this.requestForgetPasswordEmailError,
        this.load
      )
    };
    requestForgetPasswordEmailSuccess = (payload) => requestSuccess('Recover email is sent. Please check your email.')
    requestForgetPasswordEmailError = (error) => requestError( error.message, 'Error' )

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          errorMessage={this.state.errorMessage}
          onLoadLogin={this.state.loading}
          onChangeHOC={this.onChangeHOC}
          requestForgetPasswordEmail={this.requestForgetPasswordEmail}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;