import _ from "lodash";
import { Component } from "react";
import { connect } from 'react-redux';

import { defaultForm } from './assets'
import { setPath } from 'actions/path';
import { Get, Post } from "utils/axios";
import { convertObjectToBase64 } from "utils/objToBase64";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      errors: [],
      machines: [],
      controllers: [],
      formData: defaultForm,
      selectedClient: null,
      preSelectedMachine: null,
      groupClients: []
    };

    onChangeRequestHOC = (key, val) => this.setState({ [key]: val });

    load = (param) => this.setState({ loading: param });

    getMachines = (val, type) => Get(
      `/api/public/machines?query=${convertObjectToBase64({
        filter: {
          status__not_in: ["deleted"],
          ...type === 'client' && val && { customer_id: val },
          ...type === 'group' && val && { customer_id__in: _.map(val, child => child.id) },
        },
        sorter: ["serial_number", "ASC"],
        need_pagination: false
      })}`,
      this.getMachinesSuccess,
      this.getMachinesError,
      this.load
    )
    getMachinesSuccess = (response) => this.setState({ machines: response.data });
    getMachinesError = (error) => requestError("Failed to fetch machines.");

    getControllers = () =>
      Get(
        `/api/public/settings?query=${convertObjectToBase64({
          filter: { type: "controller", status__not_in: ["deleted"] },
          need_pagination: false
        })}`,
        this.getControllersSuccess,
        this.getControllersError,
        this.load
      );
    getControllersSuccess = (response) => this.setState({ controllers: response.data });
    getControllersError = (error) => requestError("Failed to fetch controllers.");

    createRequest = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      delete temp.service_request_file;
      this.load(true);

      Post(
        `/api/public/service_requests`,
        temp,
        this.createRequestSuccess,
        this.createRequestError,
        () => { }
      );
    }
    createRequestSuccess = payload => {
      this.load(false)
      this.setState({ formData: defaultForm });
      return requestSuccess("You have successfully added a new service request.", "Successful added!");
    };
    createRequestError = (error) => {
      this.load(false);
      this.setState({ errors: error.info });
      requestError(error.message, 'Error');
    };

    createAttachment = dataToSubmit =>
      Post(
        `/api/public/attachments`,
        {
          attachments: [dataToSubmit]
        },
        this.createAttachmentSuccess,
        this.createAttachmentError,
        this.load
      );
    createAttachmentSuccess = () => {
      this.setState({ formData: defaultForm });
      requestSuccess(
        "You have successfully added a new record.",
        "Successful added!"
      );
    }
    createAttachmentError = (error) => {
      this.setState({ errors: error.info });
      requestError("Failed to upload attachment.");
    }

    getSelectedClient = (id) => Get(
      `/api/public/customers?query=${convertObjectToBase64({
        filter: { id }
      })}`,
      (payload) => this.setState({ selectedClient: payload.data[0] }),
      (error) => requestError(error.message, "Error"),
      this.load
    );

    getGroupClient = (id) => Get(
      `/api/public/qr_code_groups?query=${convertObjectToBase64({
        filter: { id }
      })}`,
      this.getGroupClientSuccess,
      this.getGroupClientError,
      this.load
    )
    getGroupClientSuccess = payload => {
      this.setState({
        groupClients: payload.data?.[0]?.customers
      })
    }
    getGroupClientError = (error) => requestError(error.message, "Error");

    render = () => {
      return (
        <>
          <WrappedComponent
            {... this.props}
            {... this.state}
            onLoadRequest={this.state.loading}

            getGroupClient={this.getGroupClient}
            getSelectedClient={this.getSelectedClient}
            getMachines={this.getMachines}
            getControllers={this.getControllers}
            createRequest={this.createRequest}
            onChangeRequestHOC={this.onChangeRequestHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = state => ({ data: state })
  return connect(mapStateToProps, { setPath })(WithHOC);
};

export default HOC;
