import React from "react";
import {
	Col,
	FormGroup,
	Label,
	Input,
	InputGroupAddon,
	InputGroup,
} from "reactstrap";
import _ from "lodash";

import CustomIcon from "components/Icon";
import "./index.scss";

// // Sample
// <FormInput
//   row={true}
//   labelCol={{ xl: 2, lg: 2, md: 2 }}
//   inputCol={{ xl: 10, lg: 10, md: 10 }}
//   formGroupStyle={{ marginBottom: "6px" }}
//   type={"text"}
//   value={""}
//   label={"User ID"}
//   errors={""}
//   labelClass="text-xs font-weight-bold text-light-grey"
//   context={"user-id"}
//   required={false}
//   placeholder={"User ID"}
//   onChangeData={() => { }}
// />

const FormInput = ({
	formGroupStyle,
	formGroupClass,
	type,
	row, // boolean
	labelCol, //customize column size for label
	inputCol, //customize column size for input
	style,
	value,
	label,
	prefix,
	pattern,
	disabled, // boolean
	errors, // [ { field: 'name', message: 'Name is required' }]
	labelClass,
	context,
	required,
	maxLength,
	placeholder,
	onChangeData,
	labelChildren,
	inputClass,
	onClick,
	showToggleButton,
	onKeyUp,
	onKeyDown,
	onKeyPress,
	inputSize, //sm
}) => {
	const renderInput = () => (
		<>
			<InputGroup className="custom-input-group">
				{prefix && <span className="custom-input-prefix">{prefix}</span>}
				<Input
					type={type || "text"}
					disabled={disabled}
					value={value}
					style={style}
					pattern={pattern}
					maxLength={maxLength}
					className={`custom-form-input ${inputClass || ""} ${
						inputSize ? `custom-form-input-${inputSize}` : ""
					}`}
					placeholder={placeholder || ""}
					onChange={(e) => onChangeData(e.target.value)}
					onClick={onClick}
					onKeyUp={onKeyUp}
					onKeyDown={onKeyDown}
					onKeyPress={onKeyPress}
				/>
				{showToggleButton && (
					<button
						className="toggle-button"
						onClick={onClick}
						onMouseDown={(e) => e.preventDefault()}>
						<CustomIcon custom="dots-horizontal" />
					</button>
				)}
			</InputGroup>
			<span className="text-danger">
				{errors?.length > 0 &&
					_.find(errors, (obj) => obj.field === context)?.message}{" "}
			</span>
		</>
	);

	return (
		<FormGroup
			style={{ ...formGroupStyle }}
			className={`${formGroupClass || ""}`}
			row={row || false}
			required={required}>
			{label !== undefined && (
				<Label
					{...(row ? labelCol : {})}
					className={`custom-form-label ${labelClass || ""}`}
					for={context}>
					{label}
					{required ? <span className="text-danger">*</span> : ""}
					{labelChildren}
				</Label>
			)}
			{row ? <Col {...(row && inputCol)}>{renderInput()}</Col> : renderInput()}
		</FormGroup>
	);
};

export default FormInput;
