import React from "react";

import "./index.scss";

const Tab = ({
	className,
	style,
	tabSections, // [{label: "Quotation",value: this.props.quotation}]
	selectedSection,

	onChangeSection,
}) => {
	return (
		<div className={`custom-tabs ${className || ""}`} style={{ ...style }}>
			{tabSections.map((tabSection, index) => (
				<div
					key={`tab_${index}`}
					onClick={() => onChangeSection(tabSection.value)}
					className={`custom-tab-section ${
						tabSection.value === selectedSection ? "custom-tab-selected" : ""
					}`}>
					{tabSection.label}
				</div>
			))}
		</div>
	);
};

export default Tab;
