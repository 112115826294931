import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";

import Login from "./containers/Login";
import ResetPwForm from "containers/ResetPwForm";
import ForgotPw from "containers/ForgotPw";
import Dashboard from "./containers/Dashboard";
import Registration from "./containers/Registration";
import PublicServiceRequest from './containers/PublicServiceRequest'
import { getItem } from "utils/tokenStore";
import { getProfile } from "actions/profile";

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={(props) => <Component {...props} />} />
);

function CustomRouter(props) {
	useEffect(() => {
		if (getItem("MAZAK_ACCESS_TOKEN")) {
			props.getProfile();
		}
	}, []);

	return (
		<Switch>
			<Route exact path="/login" component={Login} initialPath />
			<Route exact path="/register" component={Registration} />
			<Route exact path="/reset-password-form" component={ResetPwForm} />
			<Route exact path="/reset-password" component={ForgotPw} />
			<Route exact path="/forgot-password" component={ForgotPw} />
			<Route exact path="/public-service-request" component={PublicServiceRequest} />
			<PrivateRoute path="/dashboard" component={Dashboard} />
			<Redirect from={"/"} to={"/login"} />
		</Switch>
	);
}

export default compose(connect(null, { getProfile }), withRouter)(CustomRouter);
