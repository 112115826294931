import React, { lazy } from "react";

const CustomDashboard = lazy(() => import("./Home"));
const ServiceRequest = lazy(() => import("./ServiceRequest"));
const Clients = lazy(() => import("./Clients"));
const Dealers = lazy(() => import("./Dealers"));
const Machines = lazy(() => import("./Machines"));
const TechnologyAnalysis = lazy(() =>
	import("./Reportings/TechnologyAnalysis")
);
const MachinesSupplied = lazy(() => import("./Reportings/MachinesSupplied"));
const ServiceEngineer = lazy(() =>
	import("./Reportings/ServiceEngineerHourContributions")
);
const FaultyTypeAnalysis = lazy(() =>
	import("./Reportings/FaultyTypeAnalysis")
);
const ChargableUnderParticular = lazy(() =>
	import("./Reportings/ChargableUnderParticular")
);
const ServiceCompletion = lazy(() => import("./Reportings/ServiceCompletion"));
const ServiceRequestResponse = lazy(() =>
	import("./Reportings/ServiceRequestResponseRate")
);
const Users = lazy(() => import("./UserSettings/Users"));
const ExternalEngineers = lazy(() =>
	import("./UserSettings/ExternalEngineers")
);
const MachineModels = lazy(() => import("./UserSettings/MachineModels"));
const Controllers = lazy(() => import("./UserSettings/Controllers"));
const Tools = lazy(() => import("./UserSettings/Tools"));
const Factory = lazy(() => import("./UserSettings/Factory"));
const NC = lazy(() => import("./UserSettings/NC"));
const Technology = lazy(() => import("./UserSettings/Technology"));
const ServiceType = lazy(() => import("./UserSettings/ServiceTypes"));
const EngineerAttendance = lazy(() => import("./EngineerAttendance"));
const QRGrouping = lazy(() => import("./QRGrouping"));
const Attendance = lazy(() => import("./Attendance"));

export const routes = [
	{
		path: "/home",
		exact: true,
		render: (props) => <CustomDashboard {...props} />,
	},
	{
		path: "/service_request",
		exact: true,
		render: (props) => <ServiceRequest {...props} />,
	},
	{
		path: "/clients",
		exact: true,
		render: (props) => <Clients {...props} />,
	},
	{
		path: "/dealers",
		exact: true,
		render: (props) => <Dealers {...props} />,
	},
	{
		path: "/machines",
		exact: true,
		render: (props) => <Machines {...props} />,
	},
	{
		path: "/attendance",
		exact: true,
		render: (props) => <Attendance {...props} />,
	},
	{
		path: "/reportings/chargeable_under_particular_request",
		exact: true,
		render: (props) => <ChargableUnderParticular {...props} />,
	},
	{
		path: "/reportings/service_case_based_on_technology_analysis",
		exact: true,
		render: (props) => <TechnologyAnalysis {...props} />,
	},
	{
		path: "/reportings/machines_supplied_factories_under_warranty_analysis",
		exact: true,
		render: (props) => <MachinesSupplied {...props} />,
	},
	{
		path: "/reportings/service_engineer_hour_contributions",
		exact: true,
		render: (props) => <ServiceEngineer {...props} />,
	},
	{
		path: "/reportings/service_case_based_on_faulty_type_analysis",
		exact: true,
		render: (props) => <FaultyTypeAnalysis {...props} />,
	},
	{
		path: "/reportings/service_completion_rate",
		exact: true,
		render: (props) => <ServiceCompletion {...props} />,
	},
	{
		path: "/reportings/service_request_response_rate",
		exact: true,
		render: (props) => <ServiceRequestResponse {...props} />,
	},
	{
		path: "/settings/users",
		exact: true,
		render: (props) => <Users {...props} />,
	},
	{
		path: "/settings/external_engineers",
		exact: true,
		render: (props) => <ExternalEngineers {...props} />,
	},
	{
		path: "/settings/machine_models",
		exact: true,
		render: (props) => <MachineModels {...props} />,
	},
	{
		path: "/settings/controllers",
		exact: true,
		render: (props) => <Controllers {...props} />,
	},
	{
		path: "/settings/tools",
		exact: true,
		render: (props) => <Tools {...props} />,
	},
	{
		path: "/settings/factory",
		exact: true,
		render: (props) => <Factory {...props} />,
	},
	{
		path: "/settings/nc",
		exact: true,
		render: (props) => <NC {...props} />,
	},
	{
		path: "/settings/technology",
		exact: true,
		render: (props) => <Technology {...props} />,
	},
	{
		path: "/settings/service_types",
		exact: true,
		render: (props) => <ServiceType {...props} />,
	},
	{
		path: '/reportings/engineer_attendance',
		exact: true,
		render: (props) => <EngineerAttendance {...props} />,
	},
	{
		path: '/qr-grouping',
		exact: true,
		render: (props) => <QRGrouping {...props} />
	}
];
