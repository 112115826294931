import { GET_PROFILE } from "../actions/type";

const initialState = {
  profile: {
    role_id: 1,
    email: "superadmin@bkp.com",
    first_name: "Super",
    last_name: "Admin",
    created_at: "2021-12-05T18:14:27.268853Z",
    updated_at: "2021-12-05T18:14:27.274905Z",
  },
};

export const setUserProfile = payload => ({
  type: GET_PROFILE,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};
