import { combineReducers } from "redux";

import ThemeOptions from "./ThemeOptions";
import ProfileReducer from "./profile";
import AjaxReducer from "./ajax";
import PathReducer from './path'

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  ProfileReducer,
  PathReducer
});
