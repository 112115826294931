import React from "react";
import { Col, FormGroup, Label, Input } from "reactstrap";
import _ from "lodash";

import "./index.scss";

// // Sample
// <SelectInput
// 	row={true}
// 	labelCol={{ xl: 3, lg: 4, md: 5 }}
// 	inputCol={{ xl: 9, lg: 8, md: 7 }}
// 	formGroupStyle={{ marginBottom: "6px" }}
// 	type={"select"}
// 	value={""}
// 	label={"Copy Permission From"}
// 	errors={""}
// 	labelClass="text-xs font-weight-bold text-light-grey"
// 	context={"copy-permission-from"}
// 	required={false}
// 	placeholder={"Name"}
// 	onChangeData={() => { }}
// />

const FormInputSelect = ({
	formGroupStyle,
	formGroupClass,
	value,
	row, // boolean
	labelCol,
	inputCol,
	label,
	options,
	optionLabelKey,
	optionValuelKey,
	emptyOption,
	errors, // [ { field: 'name', message: 'Name is required' }]
	labelClass,
	labelStyle,
	context,
	required,
	placeholder,
	onChangeData,
	inputClass,
	disabled,
	inputSize, //sm
	onClick
}) => {
	const InputContent = () => {
		return (
			<>
				<Input
					type={"select"}
					value={value}
					className={`custom-form-input ${inputClass || ""} ${
						inputSize ? `custom-form-input-${inputSize}` : ""
					} ${emptyOption ? "custom-select-placeholder" : ""}`}
					placeholder={placeholder || ""}
					disabled={disabled}
					onChange={(e) => onChangeData(e.target.value)}
					onClick={onClick}>
					{emptyOption && <option value={""}>{placeholder}</option>}
					{options?.map((opt, optIndex) => {
						return (
							<option
								key={`option_${optIndex}`}
								value={optionValuelKey ? opt[optionValuelKey] : opt.value}>
								{optionLabelKey ? opt[optionLabelKey] : opt.label}
							</option>
						);
					})}
				</Input>
				<span className="text-danger">
					{errors?.length > 0 &&
						_.find(errors, (obj) => obj.field === context)?.message}{" "}
				</span>
			</>
		);
	};

	return (
		<FormGroup
			style={{ ...formGroupStyle }}
			className={`${formGroupClass || ""}`}
			row={row || false}
			required={required}>
			{label && (
				<Label
					{...(row ? labelCol : {})}
					className={`custom-form-label ${labelClass || ""}`}
					style={{ ...labelStyle }}
					for={context}>
					{label}
					{required ? <span className="text-danger">*</span> : ""}
				</Label>
			)}
			{row ? (
				<Col {...(row && inputCol)}>
					<InputContent />
				</Col>
			) : (
				<InputContent />
			)}
		</FormGroup>
	);
};

export default FormInputSelect;
