import _ from "lodash";
import { useRef } from "react";
import { Label } from "reactstrap";

import CustomButton from "components/Button";
import CustomIcon from "components/Icon";
import { AiOutlinePlus } from "react-icons/ai";
import { FiImage, FiFile } from 'react-icons/fi'

const FileUpload = ({
	type, // image, file
	errors,
	value, // [], {}
	label,
	labelClass,
	labelStyle,
	required,
	accept, // image/png, image/jpeg, image/jpg, application/pdf,
	onChange,
	multiple,
	disabled,
	helptext,
	uploadButtonStyle,
	hideDelete,
	buttonLabel,
	onClickRemove,
	buttonColor,
	hidePlusIcon,
	customClass,
	hideUploadButton,
	context
}) => {
	let filepond = useRef(null);

	const renderFileIcon = (type) => {
		if (type?.includes("image")) {
			return <FiImage />
		} else {
			return <FiFile />
		}
	};

	const renderFile = (item, index) => (
		<div key={index} className={`custom-uploded-file ${customClass || ""}`}>
			<div>
				<div className="custom-uploded-file__icon">
					{renderFileIcon(item.file_type)}
				</div>
				<div className="d-flex flex-column">
					<h2 style={{ wordBreak: "break-word" }}>{item.file_name}</h2>
					<span>{Number(item.file_size) / 1000} KB</span>
				</div>
			</div>
			{
				!hideDelete && (
					<CustomButton
						color={"borderless"}
						customStyle={{ borderRadius: "50%" }}
						onClick={() => onClickRemove(multiple ? index : "")}>
						<CustomIcon featherIcon={"FiTrash"} />
					</CustomButton>
				)
			}
		</div>
	);

	return (
		<>
			<div className="custom-file-upload">
				{label && (
					<Label className={`custom-form-label ${labelClass || ""}`} style={{ ...labelStyle }}>
						{label}
						{required && <span className="text-danger">*</span>}
					</Label>
				)}
				{_.isArray(value) && value.length > 0 && (
					<div className="d-flex flex-wrap g-2">
						{value.map((item, index) => renderFile(item, index))}
					</div>
				)}
				{!_.isArray(value) && value.file_name && (
					<div className="d-flex flex-wrap g-2">{renderFile(value, 0)}</div>
				)}
				{
					!hideUploadButton && (
						<div className="custom-file-upload-input">
							<CustomButton
								color={buttonColor || "white"}
								size={"lg"}
								disabled={!multiple && !_.isEmpty(value)}
								style={{ ...uploadButtonStyle }}
								onClick={() => filepond.current.click()}>
								{!hidePlusIcon && <AiOutlinePlus style={{ marginRight: 2 }} size={16} />}
								{buttonLabel || "Upload File"}
							</CustomButton>
							<p>{helptext}</p>
							<input
								type={'file'}
								accept={accept || ".jpg,.jpeg,.png,.webp"}
								onChange={onChange}
								multiple={multiple}
								disabled={disabled}
								ref={filepond}
								style={{ display: "none" }}
							/>
						</div>
					)
				}
			</div>
			<span className="text-danger">
				{errors?.length > 0 && context &&
					_.find(errors, (obj) => obj.field === context)?.message}{" "}
			</span>
		</>
	);
};

export default FileUpload;
