import React, { useEffect, useState } from "react";
import { Form, Button } from "reactstrap";
import { Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import { GrView, GrFormViewHide } from "react-icons/gr";
import { HiOutlineExclamation, HiOutlineCheckCircle } from "react-icons/hi";
import _ from "lodash";

import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import CustomFormInput from "components/Input/formInput";
import WithVerifyRegistration from "./action";

import LogoImg from "assets/Images/logo.png";

const Registration = (props) => {
	const useQuery = () => new URLSearchParams(useLocation().search);
	let query = useQuery();

	const [formData, updateFormData] = useState({});
	const [showPassword, updateShowPassword] = useState(false);
	const [showPasswordConfirmation, updateShowPasswordConfirmation] =
		useState(false);

	useEffect(() => {
		if (props.match.path === "/register") {
			props.verifyRegistration(query.get("token"));
		}
	}, [props.match.path]);

	return (
		<TemplateContainerMain>
			<div className="h-100">
				<Row className="h-100">
					<Col
						span={24}
						className="h-100 d-flex justify-content-center align-items-center"
						style={{ background: "#1c212d" }}>
						<Col
							xl={8}
							lg={12}
							md={18}
							sm={24}
							xs={24}
							className="mx-auto app-login-box">
							<img
								src={LogoImg}
								alt="mazak-logo"
								style={{ width: 200 }}
								className="d-block mx-auto mb-5"
							/>
							<Form
								style={{
									background: "#f9fafb",
									padding: "25px",
									borderRadius: "10px",
								}}>
								{!_.isEmpty(props.userAccount) ? (
									props.userAccount.register_at === null ? (
										<>
											<h5
												style={{
													color: "#cb1619",
													fontWeight: "600",
												}}
												className="mb-1">
												Registration
											</h5>
											<div className="mb-4">
												Fill out your password to register.
											</div>
											<CustomFormInput
												context="password"
												label="Password"
												type={showPassword ? "text" : "password"}
												value={formData.password}
												placeholder="Password"
												onChangeData={(val) =>
													updateFormData({ ...formData, password: val })
												}
												errors={props.registerError}
												labelClass={"d-flex"}
												labelChildren={
													<div
														style={{
															width: 15,
															height: 15,
															marginLeft: 10,
															cursor: "pointer",
														}}
														onClick={() => updateShowPassword(!showPassword)}>
														{showPassword ? (
															<GrView
																style={{ width: "100%", height: "100%" }}
															/>
														) : (
															<GrFormViewHide
																style={{ width: "100%", height: "100%" }}
															/>
														)}
													</div>
												}
											/>
											<CustomFormInput
												context="password_confirmation"
												label="Password Confirmation"
												type={showPasswordConfirmation ? "text" : "password"}
												value={formData.password_confirmation}
												placeholder="Password Confirmation"
												onChangeData={(val) =>
													updateFormData({
														...formData,
														password_confirmation: val,
													})
												}
												errors={props.registerError}
												labelClass={"d-flex"}
												labelChildren={
													<div
														style={{
															width: 15,
															height: 15,
															marginLeft: 10,
															cursor: "pointer",
														}}
														onClick={() =>
															updateShowPasswordConfirmation(
																!showPasswordConfirmation
															)
														}>
														{showPasswordConfirmation ? (
															<GrView
																style={{ width: "100%", height: "100%" }}
															/>
														) : (
															<GrFormViewHide
																style={{ width: "100%", height: "100%" }}
															/>
														)}
													</div>
												}
											/>
											<Row className="divider mx-1" />
											<Button
												color="primary btn-block my-4"
												size="lg"
												disabled={
													!formData.password || !formData.password_confirmation
												}
												onClick={() =>
													props.register({
														...formData,
														token: query.get("token"),
													})
												}>
												REGISTER NOW
											</Button>
										</>
									) : (
										<>
											<h5
												className="text-center py-2"
												style={{ color: "#039855" }}>
												<HiOutlineCheckCircle
													style={{
														marginRight: "10px",
														fontSize: "25px",
													}}
												/>
												You have successfully registered !
												<br />
												Click the button below to Login.
											</h5>
											<Button
												color="primary btn-block mt-4 mb-3"
												size="lg"
												onClick={() => props.history.push("/login")}>
												LOGIN NOW
											</Button>
										</>
									)
								) : (
									<h5
										className="text-center"
										style={{ color: "var(--primary)" }}>
										<HiOutlineExclamation
											style={{
												marginRight: "10px",
												fontSize: "25px",
											}}
										/>
										Your token is invalid !
									</h5>
								)}
							</Form>
						</Col>
					</Col>
				</Row>
			</div>
			{props.onLoadRegistration && <LoadingOverlay />}
		</TemplateContainerMain>
	);
};

export default WithVerifyRegistration(Registration);
