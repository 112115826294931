import React, { useState } from "react";
import { Button, Label } from "reactstrap";
import { Row, Col } from "antd";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import _ from "lodash";

import TemplateContainerMain from "components/Template";
import LoadingOverlay from "components/Indicator/LoadingOverlay";
import LogoImg from "assets/Images/logo.png";
import WithResetPwForm from "./actions";

const ResetPwForm = (props) => {
	const [email, setEmail] = useState("");

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			if (email.length < 1) {
				return false;
			}
			props.requestForgetPasswordEmail(email);
		}
	};

	return (
		<>
			<TemplateContainerMain>
				<div className="h-100">
					<Row className="h-100">
						<Col
							span={24}
							className="h-100 d-flex justify-content-center align-items-center"
							style={{ background: "#1c212d" }}>
							<Col
								xl={8}
								lg={12}
								md={18}
								sm={24}
								xs={24}
								className="mx-auto app-login-box">
								<img
									src={LogoImg}
									alt="mazak-logo"
									style={{ width: 200 }}
									className="d-block mx-auto mb-5"
								/>
								<AvForm
									style={{
										background: "#f9fafb",
										padding: "25px",
										borderRadius: "10px",
									}}>
									<h5
										style={{
											color: "#ff6600",
											fontWeight: "600",
										}}
										className="mb-1">
										Reset Password
									</h5>
									<div className="mb-4">
										Forgotten your password? We'll email you instructions to
										reset your password.
									</div>
									<Row gutter={16}>
										<Col span={24}>
											<AvGroup>
												<Label for="resetPwFormEmail">Email</Label>
												<AvInput
													onChange={(e) => {
														setEmail(e.target.value);
													}}
													type="email"
													name="email"
													id="resetPwFormEmail"
													placeholder="Email"
													onKeyPress={handleKeyPress}
													required
												/>
											</AvGroup>
										</Col>
									</Row>
									{props.errorMessage && (
										<p style={{ color: "#ff0000" }}>{props.errorMessage}</p>
									)}
									<Row className="divider mx-1" />
									<Button
										color="primary btn-block my-4"
										size="lg"
										disabled={!email}
										onClick={() => {
											props.requestForgetPasswordEmail({ email: email });
										}}>
										Reset Password
									</Button>
								</AvForm>
							</Col>
						</Col>
					</Row>
				</div>
				{props.onLoadLogin && <LoadingOverlay />}
			</TemplateContainerMain>
		</>
	);
};

export default WithResetPwForm(ResetPwForm);
