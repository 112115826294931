import React, { Component } from "react";
import _ from "lodash";

import { Get, Post } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      userAccount: {},
      registerError: []
    }

    onChangeUserAccHOC = (key, val) => this.setState({ [key]: val })

    load = (param) => this.setState({ loading: param })

    verifyRegistration = (token) => Get(
      `/api/user_accounts/${token}/verify_registration`,
      this.verifyRegistrationSuccess,
      this.verifyRegistrationError,
      this.load
    )
    verifyRegistrationSuccess = (payload) => this.setState({ userAccount: payload.data })
    verifyRegistrationError = error => requestError(error)

    register = (data) => Post(
      `/api/user_accounts/register`,
      data,
      this.registerSuccess,
      this.registerError,
      this.load
    )
    registerSuccess = (payload) => {
      requestSuccess(
        "You have successfully registered",
        "Successfully Registered."
      )
      this.setState({ userAccount: payload.data })
    }
    registerError = error => {
      this.setState({ registerError: error.info })
      requestError(error)
    }

    render = () => {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          onLoadRegistration={this.state.loading}
          verifyRegistration={this.verifyRegistration}
          register={this.register}
        />
      )
    }
  }
  return WithHOC
}

export default HOC
