import React from "react";
import { Button } from "reactstrap";

import "./index.scss";

//Sample
{
	/* <CustomButton
	color={"white"}
	size={"sm"}
	onClick={true}
	children={"Cancel"}>
</CustomButton> */
}

const CustomButton = ({
	color, //white, primary, borderless
	size, //sm, md, lg
	onClick, //function
	children,
	style, // { padding: 10 }
	className, // "d-flex align-items-center"
	disabled,
}) => {
	return (
		<Button
			style={{ ...style }}
			color={ color }
			className={`custom-button custom-button__${color} ${className || ""}`}
			size={size || "md"}
			onClick={onClick}
			disabled={disabled}>
			{children}
		</Button>
	);
};

export default CustomButton;
