import React from "react";

import "./index.scss";

// Sample
{/* <CustomCard
mode={"borderless"}
leftButtons={[
  {
    children: "Back",
    "color": "white",
    onClick: () => console.log("back"),
  },
]}
rightButtons={[
  {
    children: "Submit",
    color: "red",
    onClick: () => console.log("submit"),
  },
]}
>
	"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex neque, maxime impedit sit iste, natus totam libero reiciendis ratione eveniet iusto, non sed saepe consectetur quibusdam? Explicabo in ipsa aperiam quidem? Architecto, soluta impedit. Ab omnis tempore enim facilis neque quisquam eum asperiores nam aliquid amet! Vero dignissimos necessitatibus culpa?"
</CustomCard> */}

const CustomCard = ({
  mode, //default, borderless, dashed
  className,
  style,
  id,

  children,
}) => {
  return (
    <div
      style={{ ... style }}
      id={id||''}
      className={`custom-card custom-card-${mode || "default"} ${className || ""
        }`}>
      {children}
    </div>
  );
};

export default CustomCard;
