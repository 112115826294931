export default () => {
  if (window.location.host.indexOf("localhost") !== -1) {
    return "https://mazak-backend-staging.navarch.app";
  }
  if (window.location.host.indexOf("staging") !== -1) {
    return "https://mazak-backend-staging.navarch.app";
  }
  if (window.location.host.indexOf("app.mazakmalaysia") !== -1) {
    return "https://api.mazakmalaysia.com";
  }
  return "https://mazak-backend-staging.navarch.app";
};
